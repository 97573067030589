<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 关于我们 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <div id="aboutus" class="aboutusBox column a_center w-per100 mt88">
      <div class="w-per100 column a_center">
        <!-- <div class="row flex_center mt100 w-per100 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1">
          <div class="line"></div>
          <div class="show_color ft36 mx30">
            关于我们
          </div>
          <div class="line"></div>
        </div> -->

        <div class="w-per100 px60 column a_center minh896 relative">
          <div class="setHeight w-per100 minh896 aboutus">
            <!-- 背景 -->
          </div>
          <div class="getHeight w-per100 column a_center absolute top0 left0 zIndex10 pb192">
            <div class="w-per62_5 row j_sb a_center mt50">
              <div class="w-per50">
                <div class="ft30">
                  公司介绍
                </div>
                <div class="ft20 mt20 lh46">
                  {{ about[0] ? '&emsp;&emsp;'+about[0].description : '' }}
                </div>
              </div>
              <div class="w-per45 h350 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
                <img class="w-per100 h-per100" :src="about[0] ? about[0].thumb : ''" alt="">
              </div>
            </div>

            <div class="w-per62_5 row j_sb a_center mt30">
              <div class="w-per45 h350 wow animate__animated animate__zoomIn mt20" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
                <img class="w-per100 h-per100" :src="about[1] ? about[1].thumb : ''" alt="">
              </div>
              <div class="w-per50">
                <div class="ft20 lh46">
                  {{ about[1] ? '&emsp;&emsp;'+about[1].description : '' }}
                </div>
                <div class="w-per80 mt38 row j_sb a_center">
                  <div class="column a_center" v-for="(item,index) in about_icon" :key="index">
                    <div class="lh46 mb15 ft24 show_color" v-if="item.description">
                        {{ item.description }}
                    </div>
                    <div class="wh46 mb15" v-else>
                        <img class="w-per100 h-per100" :src="item.thumb" alt="">
                    </div>
                    <div class="ft16 show_color">
                      {{item.title}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 期待与您的合作 -->
    <div id="cooperation" class="cooperationBox column a_center w-per100 minh500">
      <div class="w-per62_5 column a_center">
        <div class="row flex_center mt100 w-per100 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1">
          <div class="line"></div>
          <div class="show_color ft36 mx30">
            期待与您的合作
          </div>
          <div class="line"></div>
        </div>

        <div class="column a_center mt75 ">
          <el-form :inline="true" :model="ruleForm" :rules="rules" class="">
            <el-form-item>
              <el-input v-model="ruleForm.name" placeholder="您的称呼">
                <img slot="prefix" class="wh28 mt_icon" src="https://cheyoutai.com/common/icon_nindechenghu.png" alt="">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm.company" placeholder="公司名称">
                <img slot="prefix" class="wh28 mt_icon" src="https://cheyoutai.com/common/icon_gongsimingcheng.png" alt="">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm.phone" placeholder="您的电话">
                <img slot="prefix" class="wh28 mt_icon" src="https://cheyoutai.com/common/icon_dianhua.png" alt="">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm.mail" placeholder="您的邮箱">
                <img slot="prefix" class="wh28 mt_icon" src="https://cheyoutai.com/common/icon_youxiang.png" alt="">
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <div class="pointer mt70 mb20 ft24 white_color w250 lh56 text_center br28 bg_btn" @click="to_sub">
          提 交
        </div>
      </div>
    </div>

    <!-- 联系我们 -->
    <!-- <div id="contactus" class="contactBox column a_center w-per100">
      <div class="w-per62_5 column a_center">
        <div class="row flex_center mt-100 w-per100 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1">
          <div class="line"></div>
          <div class="show_color ft36 mx30">
            联系我们
          </div>
          <div class="line"></div>
        </div>

        <div class="mt80 column a_center">
          <el-form :inline="true" :model="ruleForm1" :rules="rules1" class="">
            <el-form-item>
              <el-input v-model="ruleForm1.name" placeholder="姓名">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm1.phone" placeholder="手机">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm1.mail" placeholder="邮箱">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm1.company" placeholder="企业名称">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm1.industry" placeholder="行业">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm1.demands" placeholder="合作诉求">
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <div class="pointer mt10 ft24 white_color w250 lh56 text_center br28 bg_btn" @click="to_sub1">
          立即提交
        </div>

        
        <div class="w-per100 column a_center">
          <div class="ft30 mt66">
            合作说明
          </div>
          <div class="w-per100 shadow1 p40 mt40 mb60 bg_w">
            <div class="row" :class="index==0?'':'mt40'" v-for="(item,index) in cooperation" :key="index">
              <div class="wh60">
                <img class="wh60" :src="item.thumb" alt="">
              </div>
              <div class="row flex1 mt12">
                <div class="mx20 ft24 lh28 text_nowrap">
                  {{index==0?'合作愿景':'合作理念'}}
                </div>
                <div class="ft20 lh28 flex1">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import {getSeoList, getAbout, consultSub, otherVal} from "@/api/app"
export default {
  metaInfo() {
    return {
      title: '关于我们',
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.cheyoutai.com/aboutus',
        },
        {
          property: "og:image",
          content: 'https://www.cheyoutai.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      cooperation:[],//合作说明
      about_icon:[],
      about:[],//关于我们
      // ruleForm1: {
      //   name: "",//姓名
      //   company: "",//公司名称
      //   phone: "",
      //   mail: "",
      //   industry:'',
      //   demands:'',
      // },
      // rules1: {
      //   name: [{required: true, message: "请输入您的姓名", trigger: "blur"}],
      //   phone: [{required: true, message: "请输入您的电话", trigger: "blur"}],
      //   mail: [{required: true, message: "请输入您的邮箱", trigger: "blur"}],
      //   company: [{required: true, message: "请输入企业名称", trigger: "blur"}],
      //   industry: [{required: true, message: "请输入行业", trigger: "blur"}],
      //   demands: [{required: true, message: "请输入合作诉求", trigger: "blur"}],
      // },
      ruleForm: {
        name: "",//姓名
        company: "",//公司名称
        phone: "",
        mail: "",
      },
      rules: {
        name: [{required: true, message: "请输入您的称呼", trigger: "blur"}],
        company: [{required: true, message: "请输入公司名称", trigger: "blur"}],
        phone: [{required: true, message: "请输入您的电话", trigger: "blur"}],
        mail: [{required: true, message: "请输入您的邮箱", trigger: "blur"}],
      },
      otherdata:{},
      title:'关于我们',
      enTitle:'About us',
      // current: 4,
      current: 2,
    }
  },
  mounted() {
    this.fetchSeoList()

    this.fetchAbout()
    this.fetchOtherVal()

    // 监听视口大小
    window.onresize=()=>{
      let getH = document.querySelector('.getHeight');
      let setH = document.querySelector('.setHeight');
      // console.log(getH.offsetHeight);
      setH.style.height = getH.offsetHeight + 'px';
    }
  },
  destroyed(){
    window.onresize = function(e){
      if(e && e.preventDefault){
        e.preventDefault();			// 阻止默认事件
      }else{
        window.event.returnValue = false;
      }
      return false;
    }
  },
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      // console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name?this.tit.zd_name:'杭州车汇'
      this.PagekeyWords = this.tit.wz_keyword?this.tit.wz_keyword:'杭州车汇'
      this.PageDescription = this.tit.zd_desc?this.tit.zd_desc:'杭州车汇信息服务有限公司'
    },
    // 提交
    async to_sub() {
      // console.log("表单", this.ruleForm);
      if(!this.checkForm()){
        return ;
      }else{
        let {data} = await consultSub({
          name: this.ruleForm.name,
          company: this.ruleForm.company,
          phone: this.ruleForm.phone,
          mail: this.ruleForm.mail,
          type: 1,
        })
        if (data.code == 1) {
          this.$message({
            message: "提交成功",
            type: "success",
          })
          this.ruleForm.name = ''
          this.ruleForm.company = ''
          this.ruleForm.phone = ''
          this.ruleForm.mail = ''
        } else {
          this.$message.error(data.msg)
        }
      }
    },
    checkForm(){
      if(this.ruleForm.name == ''){
        this.$message.error('请输入您的称呼');
        return false;
      }else if(this.ruleForm.company == ''){
        this.$message.error('请输入公司名称');
        return false;
      }else if(this.ruleForm.phone == ''){
        this.$message.error('请输入您的电话');
        return false;
      }else if(this.ruleForm.mail == ''){
        this.$message.error('请输入您的邮箱');
        return false;
      }else{
        return true;
      }
    },
    // async to_sub1() {
    //   // console.log("表单1", this.ruleForm1);
    //   if(!this.checkForm1()){
    //     return ;
    //   }else{
    //     let {data} = await consultSub({
    //       name: this.ruleForm1.name,
    //       company: this.ruleForm1.company,
    //       phone: this.ruleForm1.phone,
    //       mail: this.ruleForm1.mail,
    //       type: 2,
    //       industry: this.ruleForm1.industry,
    //       demands: this.ruleForm1.demands,
    //     })
    //     if (data.code == 1) {
    //       this.$message({
    //         message: "提交成功",
    //         type: "success",
    //       })
    //       this.ruleForm1.name = ''
    //       this.ruleForm1.company = ''
    //       this.ruleForm1.phone = ''
    //       this.ruleForm1.mail = ''
    //       this.ruleForm1.industry = ''
    //       this.ruleForm1.demands = ''
    //     } else {
    //       this.$message.error(data.msg)
    //     }
    //   }
    // },
    // checkForm1(){
    //   if(this.ruleForm1.name == ''){
    //     this.$message.error('请输入您的姓名');
    //     return false;
    //   }else if(this.ruleForm1.phone == ''){
    //     this.$message.error('请输入您的电话');
    //     return false;
    //   }else if(this.ruleForm1.mail == ''){
    //     this.$message.error('请输入您的邮箱');
    //     return false;
    //   }else if(this.ruleForm1.company == ''){
    //     this.$message.error('请输入企业名称');
    //     return false;
    //   }else if(this.ruleForm1.industry == ''){
    //     this.$message.error('请输入行业');
    //     return false;
    //   }else if(this.ruleForm1.demands == ''){
    //     this.$message.error('请输入合作诉求');
    //     return false;
    //   }else{
    //     return true;
    //   }
    // },
    handleShow(index,id) {
      // console.log(id,index)
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      // if (index == 1) {
      //   this.$router.push({
      //     path: "/ourservice",
      //   })
      // }
      // if (index == 2) {
      //   this.$router.push({
      //     path: "/advantage",
      //   })
      // }
      // if (index == 3) {
      //   this.$router.push({
      //     path: "/servicebrand",
      //   })
      // }
      if (index == 1) {
        this.$router.push({
          path: "/servicebrand",
        })
        this.handelUpdate()
      }
      if (index == 3) {
        this.$router.push({
          path: "/contactus",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      // console.log("111")
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchAbout(){
      let {data} = await getAbout()
      // console.log('getAbout',data.data);
      this.about = data.data.our_serve.about;
      this.about_icon = data.data.our_serve.about_icon;
      this.cooperation = data.data.our_serve.cooperation;
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.otherdata = data.data;

      // console.log('other',this.otherdata);
      
      let aboutus = document.querySelector('.aboutus');
      aboutus.style.backgroundImage = `url(${this.otherdata.img_one})`
      aboutus.style.backgroundSize = "100% 100%"

      let getH = document.querySelector('.getHeight');
      let setH = document.querySelector('.setHeight');
      // console.log(getH.offsetHeight);
      setH.style.height = getH.offsetHeight + 'px';

      // let contactBox = document.querySelector('.contactBox');
      // contactBox.style.backgroundImage = `url(${this.otherdata.img_seven})`
      // contactBox.style.backgroundSize = "100% 100%"

      let cooperationBox = document.querySelector('.cooperationBox');
      cooperationBox.style.backgroundImage = `url(${this.otherdata.img_three})`
      cooperationBox.style.backgroundSize = "100% 100%"
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  // padding-top: 88px;
  // padding-top: 100px;
  padding-top: 3.90625vw;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;

}
/* 期待合作 */
::v-deep .cooperationBox .el-input--prefix .el-input__inner {
    padding-left: 3.125vw;
}
::v-deep .cooperationBox .el-input__inner{
  width: 14.45vw;
  height: 2.9167vw;
  color:#333;
  font-size: 20px;
  border: 1px solid transparent;
  border-radius: 28px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  /* 第一个linear-gradient表示内填充渐变色，第二个linear-gradient表示边框渐变色 */
  background-image: linear-gradient(#fff,#fff),linear-gradient(270deg, rgba(255, 102, 51, 1), rgba(255, 198, 79, 1));
}
@media screen and (max-width: 750px){
  ::v-deep .cooperationBox .el-input__inner{
    font-size: 6px;
  }
}
::v-deep .cooperationBox .el-form-item{
  margin-right: 20px !important;
  margin-bottom: 0px;
}
::v-deep .cooperationBox .el-form-item:last-of-type{
  margin-right: 0px !important;
  margin-bottom: 0px;
}
/* 联系我们 */
::v-deep .contactBox .el-input--prefix .el-input__inner {
    padding: 15px 35px;
}
::v-deep .contactBox .el-input__inner{
  width: 18.82vw;
  height: 2.9167vw;
  color:#333;
  font-size: 20px;
  border: 1px solid transparent;
  border-radius: 28px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  /* 第一个linear-gradient表示内填充渐变色，第二个linear-gradient表示边框渐变色 */
  background-image: linear-gradient(#fff,#fff),linear-gradient(270deg, rgba(255, 102, 51, 1), rgba(255, 198, 79, 1));
}
@media screen and (max-width: 750px){
  ::v-deep .contactBox .el-input__inner{
    font-size: 6px;
  }
}
::v-deep .contactBox .el-form-item{
  margin-right: 40px !important;
  margin-bottom: 60px;
}
::v-deep .contactBox .el-form-item:nth-of-type(3n){
  margin-right: 0px !important;
}

</style>
