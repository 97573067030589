<template>
  <div class="w-per100 column flex_center footerBox bg_252525 white_color ft16">
    <div class="w-per62_5 footer row j_sb px76">
      <div class="row py28">
        <img class="logoimg2 mr120" :src="logo" alt="" />
        <div class="bd_line h160"></div>
      </div>
      <div class="row">
        <ul
          class="column"
          :class="index==list.length - 1?'':'mr80'"
          v-for="(item, index) in list"
          :key="index"
          @click="scrollTo(index, $event)"
        >
          <li class="pointer" :class="dex==0?'':'mt20'" v-for="(tem,dex) in item" :key="dex">{{ tem.catname }}</li>
        </ul>
        <div class="">
          
        </div>
      </div>
      <!-- <div class="row flex_center">
        <div class="column flex_center mr70">
          <img class="wh80" :src="erweima1" alt="">
          <div class="mt10">官方客服</div>
        </div>
        <div class="column flex_center">
          <img class="wh80" :src="erweima2" alt="">
          <div class="mt10">官方微信</div>
        </div>
      </div> -->
    </div>
    <div class="ft12 mt20 input_color row flex_center">
      <a href="https://beian.miit.gov.cn" target="_blank">{{ icp }}</a>
    </div>
  </div>
</template>
<script>
import {bottomNav, otherVal} from "@/api/app"

export default {
  data() {
    return {
      list: [],
      logo: "",
      mail:'',
      weibo:'',
      wechat:'',
      erweima1: "",
      erweima2: "",
      icp: "",
    }
  },
  props: {
    current: Number,
  },
  mounted() {
    this.list = [
        [
          {
            catname:'首页'
          },
          {
            catname:'我们的服务'
          },
          {
            catname:'车汇优势'
          },
          {
            catname:'期待合作'
          },
          // {
          //   catname:'品牌全案服务'
          // },
          // {
          //   catname:'关于我们'
          // },
        ],
        // [
        //   {
        //     catname:'我们的服务'
        //   },
        //   {
        //     catname:'推广方案'
        //   },
        //   {
        //     catname:'助力品牌'
        //   },
        //   {
        //     catname:'期待合作'
        //   },
        // ],
        // [
        //   {
        //     catname:'车汇优势'
        //   },
        //   {
        //     catname:'专业性'
        //   },
        //   {
        //     catname:'创新性'
        //   },
        //   {
        //     catname:'多元化营销'
        //   },
        //   {
        //     catname:'多渠道资源'
        //   },
        // ],
        [
          {
            catname:'品牌全案服务'
          },
          {
            catname:'品牌策划'
          },
          {
            catname:'广告创意'
          },
          {
            catname:'资源联动'
          },
        ],
        [
          {
            catname:'关于我们'
          },
          {
            catname:'公司介绍'
          },
          // {
          //   catname:'联系我们'
          // },
        ],
        [
          {
            catname:'联系我们'
          },
          {
            catname:'合作说明'
          },
        ]
      ]
    // this.fetchBottomNav()
    this.fetchOtherVal()
  },
  methods: {
    scrollTo(num, e) {
      // if(num==1){
      //   num = 3;
      // }else if(num==2){
      //   num = 4;
      // }else{
      //   num = 0;
      // }
      let data = {}
      let text = e.target.textContent
      // console.log(text, num, this.current)
      if (num == this.current) {
        // 本页面滚动
        if (text == "首页") {
          data.num = 0
        } else if (text == "品牌全案服务") {
          data.num = 1
        } else if (text == "关于我们") {
          data.num = 2
        } else if (text == "联系我们") {
          data.num = 3
        } else {
          data.num = -1
          // num=0
          if (text == "我们的服务") {
            data.id = "ourservice"
          }
          if (text == "车汇优势") {
            data.id = "advantage"
          }
          if (text == "品牌全案服务") {
            data.id = "brand"
          }
          if (text == "期待合作") {
            data.id = "cooperation"
          }
          // num=1
          if (text == "品牌策划") {
            data.id = "brand1"
          }
          if (text == "广告创意") {
            data.id = "brand2"
          }
          if (text == "资源联动") {
            data.id = "brand3"
          }
          // num=2
          if (text == "公司介绍") {
            data.id = "aboutus"
          }
          // num=3
          if (text == "合作说明") {
            data.id = "coop"
          }
        }
        data.offset = -88
      } else {
        // 跳转路由
        data.num = num;
        data.offset = -88
        // num=0
        switch (num){
          case 0:
            if (text == "我们的服务") {
              data.id = "ourservice"
            }
            if (text == "车汇优势") {
              data.id = "advantage"
            }
            if (text == "品牌全案服务") {
              data.id = "brand"
            }
            if (text == "期待合作") {
              data.id = "cooperation"
            }
            break;
          case 1:
            if (text == "品牌策划") {
              data.id = "brand1"
            }
            if (text == "广告创意") {
              data.id = "brand2"
            }
            if (text == "资源联动") {
              data.id = "brand3"
            }
            break;
          case 2:
            if (text == "公司介绍") {
              data.id = "aboutus"
            }
            break;
          case 3:
            if (text == "合作说明") {
              data.id = "coop"
            }
            break;
          default:
            break;
        }
      }
      // console.log("data", data)
      this.$emit("onScrollTo", data)
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.logo = data.data.bottom_logo
      this.mail = data.data.mail
      this.weibo = data.data.weibo
      this.wechat = data.data.wechat
      this.erweima1 = data.data.er_img
      this.erweima2 = data.data.er_img_two
      this.icp = data.data.icp
    },
    async fetchBottomNav() {
      let {data} = await bottomNav()
      // this.list = data.data.bottom_nav;
      
    },
  },
}
</script>
<style lang="scss">
.footerBox {
  // height: 280px;
  padding: 30px 0px 15px;
  .footer {
    border-bottom: 1px solid rgba(244,244,244,0.5);
  }
  a{
      color: #999999;
      text-decoration: none;
    }
    a:link{
      color:#999999;
    }
    a:visited{
      color: #999999;
    }
    a:hover{
      color: #999999;
    }
    a:active{
      color: #999999;
    }
}
</style>
