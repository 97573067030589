<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 品牌全案服务 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <!-- 品牌全案服务 -->
    <!-- <div id="brand" class="brandBox column a_center w-per100 minh830">
      <div class="w-per62_5 column a_center">
        <div class="w-per100 mt100 mb100 row wrap j_sb">
          <div class="column a_center w-per32 wow animate__animated animate__flipInX"
            data-wow-duration="0.7s"
            :data-wow-delay="(index / 3) * 0.2 + 0.2 + 's'"
            data-wow-iteration="1"
            :class="index>2?'mt30':''" v-for="(item,index) in brand" :key="item.id">
            <div class="column a_center w-per100 h-per100 bg_w br8">
              <div class="text_nowrap show_color" :class="item.title.length>4?'mt40 ft20':'mt30 ft24'">
                {{ item.title }}
              </div>
              <div class="line mt10"></div>
              <div class="px22 mt30 ft16 mb40">
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="brandBox1 column a_center w-per100 minh1300">
      <div class="w-per62_5 column a_center">
        <!-- 品牌策划 -->
        <div id="brand1" class="w-per100 column a_center">
          <div class="row flex_center mt100 w-per100 wow animate__animated animate__fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
            data-wow-iteration="1">
            <div class="line"></div>
            <div class="show_color ft36 mx30">
              品牌策划
            </div>
            <div class="line"></div>
          </div>

          <div class="w-per100 mt60 row j_sb">
            <div class="w-per49 h350 relative wow animate__animated animate__fadeInRight"
              data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.2*(index+1)+'s'"
              v-for="(item,index) in brand1" :key="index">
              <img class="w-per100 h-per100" :src="item.images" alt="">
              <div class="filterBox row a_center white_color ft24">
                <div class="ml40">
                  <img class="wh40" :src="item.thumb" alt="">
                </div>
                <div class="ml20">
                  {{item.title}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 广告策划 -->
        <div id="brand2" class="w-per100 mb148 column a_center">
          <div class="row flex_center mt140 w-per100 wow animate__animated animate__fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
            data-wow-iteration="1">
            <div class="line"></div>
            <div class="show_color ft36 mx30">
              广告策划
            </div>
            <div class="line"></div>
          </div>

          <div class="w-per100 mt60 row j_sb">
            <div class="w-per49 h350 relative wow animate__animated animate__fadeInLeft" 
              data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.2*(-index+2)+'s'"
              v-for="(item,index) in brand2" :key="index">
              <img class="w-per100 h-per100" :src="item.images" alt="">
              <div class="filterBox row a_center white_color ft24">
                <div class="ml40">
                  <img class="wh40" :src="item.thumb" alt="">
                </div>
                <div class="ml20">
                  {{item.title}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 资源联动 -->
    <div id="brand3" class="brandBox2 column a_center w-per100">
      <div class="w-per62_5 column a_center">
        
        <div class="row flex_center mt12 w-per100 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1">
          <div class="line"></div>
          <div class="show_color ft36 mx30">
            资源联动
          </div>
          <div class="line"></div>
        </div>

        <div class="w-per100">
          <div class="w-per100 mt60 row j_sb wrap">
            <div class="wow animate__animated animate__bounce"
              data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.2*(index+1)+'s'"
              :class="index==2?'w-per100 mt30':'w-per49'" 
              v-for="(item,index) in brand3" :key="index" 
              :style="{backgroundImage: 'url('+ item.thumb +')',backgroundSize: '100% 100%'}">
              <div class="w-per100 pl40 pr60" :class="index==2?'pl40 pr60':'pl40 pr60'">
                <div class="show_color ft24" :class="index==2?'mt40':'mt45'">
                  {{ item.title }}
                </div>
                <div class="line1 mt10"></div>
                <div class="w-per100 mt20 mb45" v-if="index!=2">
                  <div class="w-per100 row mt16" v-for="(it,i) in item.text" :key="i">
                    <div class="dot mt10" v-if="it"></div>
                    <div class="ft18 ml10 flex1 lh36 mt-2">
                      {{ it }}
                    </div>
                  </div>
                </div>
                <div class="w-per100 mb40" v-else>
                  <div class="w-per100 mt20" v-for="(it,i) in item.de" :key="i">
                    <div class="ft20">
                      {{ it.t }}
                    </div>
                    <div class="w-per100 row mt16" v-for="(itt,ii) in it.tt" :key="ii">
                      <div class="dot mt10"></div>
                      <div class="ft18 ml10 flex1 lh36 mt-2">
                        {{ itt }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 期待与您的合作 -->
    <div id="cooperation" class="cooperationBox column a_center w-per100 minh500">
      <div class="w-per62_5 column a_center">
        <div class="row flex_center mt100 w-per100 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1">
          <div class="line"></div>
          <div class="show_color ft36 mx30">
            期待与您的合作
          </div>
          <div class="line"></div>
        </div>

        <div class="column a_center mt75 ">
          <el-form :inline="true" :model="ruleForm" :rules="rules" class="">
            <el-form-item>
              <el-input v-model="ruleForm.name" placeholder="您的称呼">
                <img slot="prefix" class="wh28 mt_icon" src="https://cheyoutai.com/common/icon_nindechenghu.png" alt="">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm.company" placeholder="公司名称">
                <img slot="prefix" class="wh28 mt_icon" src="https://cheyoutai.com/common/icon_gongsimingcheng.png" alt="">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm.phone" placeholder="您的电话">
                <img slot="prefix" class="wh28 mt_icon" src="https://cheyoutai.com/common/icon_dianhua.png" alt="">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ruleForm.mail" placeholder="您的邮箱">
                <img slot="prefix" class="wh28 mt_icon" src="https://cheyoutai.com/common/icon_youxiang.png" alt="">
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <div class="pointer mt70 mb20 ft24 white_color w250 lh56 text_center br28 bg_btn" @click="to_sub">
          提 交
        </div>
      </div>
    </div>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import {getSeoList, getBrand, consultSub, otherVal} from "@/api/app"
export default {
  metaInfo() {
    return {
      title: '品牌全案服务',
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.cheyoutai.com/#/servicebrand',
        },
        {
          property: "og:image",
          content: 'https://www.cheyoutai.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      brand1:[],//品牌全案
      brand2:[],//广告策划
      brand3:[],//资源联动
      otherdata:{},
      ruleForm: {
        name: "",//姓名
        company: "",//公司名称
        phone: "",
        mail: "",
      },
      rules: {
        name: [{required: true, message: "请输入您的称呼", trigger: "blur"}],
        company: [{required: true, message: "请输入公司名称", trigger: "blur"}],
        phone: [{required: true, message: "请输入您的电话", trigger: "blur"}],
        mail: [{required: true, message: "请输入您的邮箱", trigger: "blur"}],
      },
      title:'品牌全案服务',
      enTitle:'Brand full service',
      // current: 3,
      current: 1,
    }
  },
  mounted() {
    this.fetchSeoList()

    this.fetchBrand();
    this.fetchOtherVal();
  },
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      // console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name?this.tit.zd_name:'杭州车汇'
      this.PagekeyWords = this.tit.wz_keyword?this.tit.wz_keyword:'杭州车汇'
      this.PageDescription = this.tit.zd_desc?this.tit.zd_desc:'杭州车汇信息服务有限公司'
    },
    async to_sub() {
      // console.log("表单", this.ruleForm);
      if(!this.checkForm()){
        return ;
      }else{
        let {data} = await consultSub({
          name: this.ruleForm.name,
          company: this.ruleForm.company,
          phone: this.ruleForm.phone,
          mail: this.ruleForm.mail,
          type: 1,
        })
        if (data.code == 1) {
          this.$message({
            message: "提交成功",
            type: "success",
          })
          this.ruleForm.name = ''
          this.ruleForm.company = ''
          this.ruleForm.phone = ''
          this.ruleForm.mail = ''
        } else {
          this.$message.error(data.msg)
        }
      }
    },
    checkForm(){
      if(this.ruleForm.name == ''){
        this.$message.error('请输入您的称呼');
        return false;
      }else if(this.ruleForm.company == ''){
        this.$message.error('请输入公司名称');
        return false;
      }else if(this.ruleForm.phone == ''){
        this.$message.error('请输入您的电话');
        return false;
      }else if(this.ruleForm.mail == ''){
        this.$message.error('请输入您的邮箱');
        return false;
      }else{
        return true;
      }
    },
    handleShow(index,id) {
      // console.log(id,index)
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      // if (index == 1) {
      //   this.$router.push({
      //     path: "/ourservice",
      //   })
      // }
      // if (index == 2) {
      //   this.$router.push({
      //     path: "/advantage",
      //   })
      // }
      // if (index == 4) {
      //   this.$router.push({
      //     path: "/aboutus",
      //   })
      // }
      if (index == 2) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      if (index == 3) {
        this.$router.push({
          path: "/contactus",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      // console.log("111")
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchBrand(){
      let {data} = await getBrand()
      // console.log('getBrand',data.data);
      // this.brand = data.data.case;
      this.brand1 = data.data.branding;
      this.brand2 = data.data.advertisement_plan;
      let arr = data.data.resource_linkage;
      arr.map((item,index) => {
        let temp = item.description.split('·')
        item.text = temp;
        if(index == 2){
          let t = []//小标题
          t.push(item.text[0])

          let array = []
          for (let i = 1; i < item.text.length; i++) {
            let el = item.text[i];
            let li = el.split('。')
            li.splice(li.indexOf(''),li.indexOf('')+1,)
            if(li.length>1){
              array.push(li[li.length - 1]);
              // console.log(item.text[i]);
            }
          }
          t.push(array[0]);
          let t3 = item.text.slice(1,3)
          for (let i = 0; i < t3.length; i++) {
            if(i == t3.length - 1){
              t3[i] = t3[i].replace(array[0],'')
            }
          }
          
          item.de = [
            {
              t:t[0],
              tt:t3
            },{
              t:t[1],
              tt:item.text.slice(3)
            }
          ]
        }
      });
      this.brand3 = arr;
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.otherdata = data.data;

      // console.log('other',this.otherdata);

      let brandBox1 = document.querySelector('.brandBox1');
      brandBox1.style.backgroundImage = `url(${this.otherdata.brand_full_case_bg_one})`
      brandBox1.style.backgroundSize = "100% 100%"

      let brandBox2 = document.querySelector('.brandBox2');
      brandBox2.style.backgroundImage = `url(${this.otherdata.brand_full_case_bg_two})`
      brandBox2.style.backgroundSize = "100% 100%"

      let cooperationBox = document.querySelector('.cooperationBox');
      cooperationBox.style.backgroundImage = `url(${this.otherdata.img_three})`
      cooperationBox.style.backgroundSize = "100% 100%"
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  // padding-top: 88px;
  // padding-top: 100px;
  padding-top: 3.90625vw;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;

  .brandBox1{
    
    .filterBox{
      overflow: hidden;
      width: 100%;
      height: 4.6875vw;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 5;
      background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(121,56,28,0.3) 49%, rgba(36, 29, 26, 0.5) 100%);
      backdrop-filter: blur(3px);
    }
    // .filterBox:after{
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   z-index: 10;
    //   background: rgba(255,255,255,0.2);
    //   filter: blur(30px);
    // }
  }
}
/* 期待合作 */
::v-deep .cooperationBox .el-input--prefix .el-input__inner {
    padding-left: 3.125vw;
}
::v-deep .cooperationBox .el-input__inner{
  width: 14.45vw;
  height: 2.9167vw;
  color:#333;
  font-size: 20px;
  border: 1px solid transparent;
  border-radius: 28px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  /* 第一个linear-gradient表示内填充渐变色，第二个linear-gradient表示边框渐变色 */
  background-image: linear-gradient(#fff,#fff),linear-gradient(270deg, rgba(255, 102, 51, 1), rgba(255, 198, 79, 1));
}
@media screen and (max-width: 750px){
  ::v-deep .cooperationBox .el-input__inner{
    font-size: 6px;
  }
}
::v-deep .cooperationBox .el-form-item{
  margin-right: 20px !important;
  margin-bottom: 0px;
}
::v-deep .cooperationBox .el-form-item:last-of-type{
  margin-right: 0px !important;
  margin-bottom: 0px;
}

</style>
