import server from "@/utils/request";


// 发请求的操作封装成函数  mock

// 首页
export function getSeoList(data){
    return server({
        method:"POST",
        url:"/api/index/seo_list",
        data
    })
}

// 首页
export function getIndex(data){
    return server({
        method:"POST",
        url:"/api/index/index",
        data
    })
}
// 底部导航
export function bottomNav(data){
    return server({
        method:"POST",
        url:"/api/index/bottom_nav",
        data
    })
}
// 其他数据
export function otherVal(data){
    return server({
        method:"POST",
        url:"/api/index/other_val",
        data
    })
}
// 提交咨询
export function consultSub(data){
    return server({
        method:"POST",
        url:"/api/index/consult_sub",
        data
    })
}
// 我们的服务
export function myServe(data){
    return server({
        method:"POST",
        url:"/api/index/my_serve",
        data
    })
}
// 车汇优势
export function getAdvantage(data){
    return server({
        method:"POST",
        url:"/api/index/advantage",
        data
    })
}
// 品牌全案服务
export function getBrand(data){
    return server({
        method:"POST",
        // url:"/api/index/brand_case",
        url:"/api/index/brand_full_case",
        data
    })
}
// 关于我们
export function getAbout(data){
    return server({
        method:"POST",
        url:"/api/index/about",
        data
    })
}