<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 联系我们 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <!-- 联系我们 -->
    <div id="contact" class="contactBox column a_center w-per100">
      <div class="w-per62_5 column a_center">
        <div class="row flex_center mt100 w-per100 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1">
          <div class="line"></div>
          <div class="show_color ft36 mx30">
            联系我们
          </div>
          <div class="line"></div>
        </div>

        <!-- 表单 -->
        <div class="mt80 w-per100 column a_center">
          <el-form :inline="true" label-position="top" :model="ruleForm1" :rules="rules1" class="w-per100">
            <el-row :gutter="30">
              <el-col :span="8">
                <el-form-item class="w-per100" label="姓名" prop="name">
                  <el-input class="inputBox" v-model="ruleForm1.name" placeholder="">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="w-per100" label="手机" prop="phone">
                  <el-input class="inputBox" v-model="ruleForm1.phone" placeholder="">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="w-per100" label="邮箱" prop="mail">
                  <el-input class="inputBox" v-model="ruleForm1.mail" placeholder="">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="8">
                <el-form-item class="w-per100" label="企业名称" prop="company">
                  <el-input class="inputBox" v-model="ruleForm1.company" placeholder="">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="w-per100" label="行业" prop="industry">
                  <el-input class="inputBox" v-model="ruleForm1.industry" placeholder="">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item class="w-per100" label="合作诉求（200字）" prop="demands">
                  <el-input class="inputBox" type="textarea" maxlength="200" autosize v-model="ruleForm1.demands" placeholder="">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item class="w-per100" label="其他相关资料（如有多个文件，请压缩打包后上传）" prop="files">
                  <el-upload
                    class=""
                    action="https://admin.cheyoutai.com/api/index/upload"
                    name="image"
                    :on-success="handleSuccess"
                    :on-error="handleError"
                    :on-remove="handleRemove"
                    :file-list="files">
                    <el-button size="small" class="uploadBtn w-per100 text_center"><span class="ft18 show_color">选择文件</span></el-button><span slot="tip" class="primary_color ft18 ml10">{{ file_name }}</span>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-form-item label="姓名">
              <el-input v-model="ruleForm1.name" placeholder="">
              </el-input>
            </el-form-item>
            <el-form-item label="手机">
              <el-input v-model="ruleForm1.phone" placeholder="">
              </el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="ruleForm1.mail" placeholder="">
              </el-input>
            </el-form-item>
            <el-form-item label="企业名称">
              <el-input v-model="ruleForm1.company" placeholder="">
              </el-input>
            </el-form-item>
            <el-form-item label="行业">
              <el-input v-model="ruleForm1.industry" placeholder="">
              </el-input>
            </el-form-item>
            <el-form-item label="合作诉求">
              <el-input v-model="ruleForm1.demands" placeholder="">
              </el-input>
            </el-form-item> -->
          </el-form>
        </div>

        <div class="pointer mt10 ft24 white_color w250 lh60 text_center br28 bg_btn" @click="to_sub1">
          立即提交
        </div>

        <!-- 合作说明 -->
        <div id="coop" class="w-per100 column a_center">
          <div class="ft30 mt66">
            合作说明
          </div>
          <div class="w-per100 shadow1 p40 mt40 mb60 bg_w">
            <div class="row" :class="index==0?'':'mt40'" v-for="(item,index) in cooperation" :key="index">
              <div class="wh60">
                <img class="wh60" :src="item.thumb" alt="">
              </div>
              <div class="row flex1 mt12">
                <div class="mx20 ft24 text_nowrap">
                  {{index==0?'合作愿景':'合作理念'}}
                </div>
                <div class="ft20 flex1 lh46 mt-2">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import {getSeoList, getAbout, consultSub, otherVal} from "@/api/app"
export default {
  metaInfo() {
    return {
      title: '联系我们',
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.cheyoutai.com/#/contactus',
        },
        {
          property: "og:image",
          content: 'https://www.cheyoutai.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      cooperation:[],//合作说明
      about_icon:[],
      about:[],//关于我们
      file_name:'',
      files:[],//暂存文件
      ruleForm1: {
        name: "",//姓名
        company: "",//公司名称
        phone: "",
        mail: "",
        industry:'',
        demands:'',
        files:[],
      },
      rules1: {
        name: [{required: true, message: "请输入您的姓名", trigger: "blur"}],
        phone: [{required: true, message: "请输入您的电话", trigger: "blur"}],
        mail: [{required: true, message: "请输入您的邮箱", trigger: "blur"}],
        company: [{required: true, message: "请输入企业名称", trigger: "blur"}],
        industry: [{required: true, message: "请输入行业", trigger: "blur"}],
        demands: [{required: true, message: "请输入合作诉求", trigger: "blur"}],
        files:[{required: false,}]
      },
      otherdata:{},
      title:'联系我们',
      enTitle:'Contact us',
      // current: 4,
      current: 3,
    }
  },
  mounted() {
    this.fetchSeoList()

    this.fetchAbout()
    this.fetchOtherVal()
  },
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      // console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name?this.tit.zd_name:'杭州车汇'
      this.PagekeyWords = this.tit.wz_keyword?this.tit.wz_keyword:'杭州车汇'
      this.PageDescription = this.tit.zd_desc?this.tit.zd_desc:'杭州车汇信息服务有限公司'
    },
    // 提交
    async to_sub1() {
      // console.log("表单1", this.ruleForm1);
      if(!this.checkForm1()){
        return ;
      }else{
        let {data} = await consultSub({
          name: this.ruleForm1.name,
          company: this.ruleForm1.company,
          phone: this.ruleForm1.phone,
          mail: this.ruleForm1.mail,
          type: 2,
          industry: this.ruleForm1.industry,
          demands: this.ruleForm1.demands,
          img:String(this.ruleForm1.files),
        })
        if (data.code == 1) {
          this.$message({
            message: "提交成功",
            type: "success",
          })
          this.ruleForm1.name = ''
          this.ruleForm1.company = ''
          this.ruleForm1.phone = ''
          this.ruleForm1.mail = ''
          this.ruleForm1.industry = ''
          this.ruleForm1.demands = ''
          this.ruleForm1.files = []
          this.files = []
          this.file_name = ''
        } else {
          this.$message.error(data.msg)
        }
      }
    },
    checkForm1(){
      if(this.ruleForm1.name == ''){
        this.$message.error('请输入您的姓名');
        return false;
      }else if(this.ruleForm1.phone == ''){
        this.$message.error('请输入您的电话');
        return false;
      }else if(this.ruleForm1.mail == ''){
        this.$message.error('请输入您的邮箱');
        return false;
      }else if(this.ruleForm1.company == ''){
        this.$message.error('请输入企业名称');
        return false;
      }else if(this.ruleForm1.industry == ''){
        this.$message.error('请输入行业');
        return false;
      }else if(this.ruleForm1.demands == ''){
        this.$message.error('请输入合作诉求');
        return false;
      }else{
        return true;
      }
    },
    // 上传文件
    handleSuccess(file, fileList) {
      console.log(file, fileList);
      this.ruleForm1.files.push(file.data.image)
      this.files.push({
        name:fileList.name,
        url:file.data.image
      })
      if(this.ruleForm1.files.length>0){
        this.file_name = ''
      }
    },
    handleError(){
      this.file_name = ''
      setTimeout(()=>{
        this.ruleForm1.files = []
        this.files = []
        this.file_name = ''
      },2000)
    },
    handleRemove(){
      this.ruleForm1.files = []
      this.files = []
      this.file_name = ''
    },
    handleShow(index,id) {
      // console.log(id,index)
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      // if (index == 1) {
      //   this.$router.push({
      //     path: "/ourservice",
      //   })
      // }
      // if (index == 2) {
      //   this.$router.push({
      //     path: "/advantage",
      //   })
      // }
      // if (index == 3) {
      //   this.$router.push({
      //     path: "/servicebrand",
      //   })
      // }
      if (index == 1) {
        this.$router.push({
          path: "/servicebrand",
        })
        this.handelUpdate()
      }
      if (index == 2) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      // console.log("111")
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchAbout(){
      let {data} = await getAbout()
      // console.log('getAbout',data.data);
      this.about = data.data.our_serve.about;
      this.about_icon = data.data.our_serve.about_icon;
      this.cooperation = data.data.our_serve.cooperation;
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.otherdata = data.data;

      // console.log('other',this.otherdata);
      
      // let aboutus = document.querySelector('.aboutus');
      // aboutus.style.backgroundImage = `url(${this.otherdata.img_one})`
      // aboutus.style.backgroundSize = "100% 100%"

      let contactBox = document.querySelector('.contactBox');
      contactBox.style.backgroundImage = `url(${this.otherdata.img_seven})`
      contactBox.style.backgroundSize = "100% 100%"
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  // padding-top: 88px;
  // padding-top: 100px;
  padding-top: 3.90625vw;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;

}
/* 联系我们 */
// ::v-deep .contactBox .el-input--prefix .el-input__inner {
//     padding: 15px 35px;
// }
::v-deep .el-form-item .el-form-item__label{
  color: #333;
}
::v-deep .inputBox input{
  width: 100%;
  height: 3.125vw;
  color:#333;
  font-size: 20px !important;
  font-family: Noto Sans SC, Noto Sans SC;
  border: 1px solid transparent;
  border-radius: 5px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  /* 第一个linear-gradient表示内填充渐变色，第二个linear-gradient表示边框渐变色 */
  background-image: linear-gradient(#fff,#fff),linear-gradient(270deg, rgba(255, 102, 51, 1), rgba(255, 198, 79, 1));
}
::v-deep .inputBox textarea{
  width: 100%;
  // padding: 15px 35px;
  min-height: 5.7292vw !important;
  color:#333;
  font-size: 20px !important;
  font-family: Noto Sans SC, Noto Sans SC;
  border: 1px solid transparent;
  border-radius: 5px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  /* 第一个linear-gradient表示内填充渐变色，第二个linear-gradient表示边框渐变色 */
  background-image: linear-gradient(#fff,#fff),linear-gradient(270deg, rgba(255, 102, 51, 1), rgba(255, 198, 79, 1));
}
::v-deep .contactBox .el-form-item{
  margin-bottom: 30px;
}

.uploadBtn{
  font-size: 18px;
  border: 1px solid transparent !important;
  border-radius: 4px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  /* 第一个linear-gradient表示内填充渐变色，第二个linear-gradient表示边框渐变色 */
  background-image: linear-gradient(#fff,#fff),linear-gradient(270deg, rgba(255, 102, 51, 1), rgba(255, 198, 79, 1));
}
@media screen and (max-width: 750px){
  ::v-deep .inputBox input{
    font-size: 6px !important;
  }
  ::v-deep .inputBox textarea{
    font-size: 6px !important;
  }
  ::v-deep .contactBox .el-form-item{
    margin-bottom: 20px;
  }
  ::v-deep .el-form-item__label{
    line-height: 20px !important;
  }
  ::v-deep .el-form-item__content{
    line-height: 20px !important;
  }
  ::v-deep .el-input{
    line-height: 20px !important;
  }
  .uploadBtn{
    font-size: 4px;
  }
}
</style>
