<template>
  <div class="w-per100 h500 column flex_center white_color" :style="{backgroundImage: 'url('+ bg +')',backgroundSize: '100% 100%'}">
    <div class="ft48 bolder mt30">{{ title }}</div>
    <div class="ft24 mt20 text_underline w_color_9">{{ enTitle }}</div>
  </div>
</template>
<script>
import { otherVal } from '@/api/app'
export default {
  data() {
    return {
      bg:''
    }
  },
  props:{
    title:String,
    enTitle:String,
    current: Number,
  },
  mounted() {
    this.fetchOtherVal()
  },
  methods: {
    async fetchOtherVal() {
      let {data} = await otherVal()
      if(this.current == 1){
        this.bg = data.data.pinpaiquananfuwu
      }
      if(this.current == 2){
        this.bg = data.data.guanyuwomen
      }
      if(this.current == 3){
        this.bg = data.data.contact_us
      }
      // if(this.current == 4){
      //   this.bg = data.data.guanyuwomen
      // }
    },
  },
}
</script>
<style lang="scss">

</style>
