<template>
  <div class="navbar row flex_center bg_FFF0E9">
    <div class="w-per62_5 row j_sb a_center">
      <img
      class="logoimg1"
      :src="logo"
      alt=""
      @click="handleShow(0)"
    />

    <ul class="row">
      <li
        class="row flex_center ft18 relative px30"
        :class="current == index?'bg_btn white_color':'primary_color'"
        v-for="(item, index) in navList"
        :key="index"
        @click="handleShow(index)"
      >
        <span>{{ item.name }}</span>
        <!-- <div v-if="current==index" class="navline"></div> -->
      </li>
    </ul>
    </div>
  </div>
</template>
<script>
import {getIndex, otherVal} from "@/api/app"
export default {
  data() {
    return {
      navList: [],
      logo: "",
    }
  },
  props: {
    current: Number,
  },
  mounted() {
    this.fetchOtherVal()
    this.fetchIndex()
  },
  methods: {
    handleShow(index) {
      this.$emit("onHandleShow", index)
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.logo = data.data.logo
    },
    async fetchIndex() {
      let {data} = await getIndex()
      // this.navList = data.data.nav_list
      this.navList = [
        {
          name:'首页',
        },
        // {
        //   name:'我们的服务',
        // },
        // {
        //   name:'车汇优势',
        // },
        {
          name:'品牌全案服务',
        },
        {
          name:'关于我们',
        },
        {
          name:'联系我们',
        },
      ]
    },
  },
}
</script>
<style lang="scss">
.navbar {
  width: 100vw;
  // 100,80,75
  height: 3.90625vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 666;
  >div{
    > ul {
      height: 3.90625vw;
      > li {
        height: 100%;
        // transition: all 0.3s linear;
      }
      > li:hover {
        cursor: pointer;
        color: #fff;
        background: linear-gradient(270deg, #FF6633 0%, #FFC64F 100%);
        // transition: all 0.3s linear;
      }
      // .navline{
      //   width: 36px;
      //   height: 3px;
      //   background: #333333;
      //   position: absolute;
      //   bottom: 0px;
      //   left: 50%;
      //   transform: translateX(-50%);
      // }
    }
  }
}
</style>
